.fade-in-section {
    opacity: 0;
    transform: translateY(90px);
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
  }
  
  .fade-in-section.is-visible {
    opacity: 1;
    transform: none;
  }
  