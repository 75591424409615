.wrapper-contact {
    width: 100%;
    padding: 20px;
    border-bottom: 4px solid #1a1a1a;
    height: 930px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #130f40;
    background-image: linear-gradient(315deg, #130f40 0%, #000000 74%);
}

form {
    display: flex;
    flex-direction: column;
    width: 800px;
}

label {
    margin-top: 30px;
    font-weight: bold;
    text-align: start;
    color: #1a1a1a;
    margin-left: 3px;
}

input,
textarea {
    margin-top: 20px;
    padding: 10px;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    background-color: transparent;
    resize: vertical;
}

textarea {
    height: 150px; /* Adjust the height as needed */
}

button {
    margin-top: 20px;
    padding: 10px;
    background-color: transparent;
    border-radius: 4px;
    cursor: pointer;
}

button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.send {
    margin-top: 20px;
    padding: 20px;
    background-color: #f9f69c; 
    color: #1a1a1a;
    border: none;
    border-radius: 8px;
    border: 3px solid #1a1a1a;
    cursor: pointer;
    font-weight: bold;
}

.send:hover {
    background-color: #cef99c; 
    color: #1a1a1a;
    transition: all 0.3s;
}

.contact-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: -80px;
}

.contact-header {
    font-size: 100px;
    font-weight: bold;
    margin-right: 340px;
    color: #c3daff;
}

.contact-subheader {
    font-size: 20px;
    margin-top: 0px;
    margin-right: 340px;
}

.contact-text {
    font-size: 20px;
    margin-right: 340px;
    margin-top: -5px;
    font-size: 1.2rem;
    font-weight: inherit;
    color:  #8f8f8f;
}

.linkedin-icon {
    color: #505050;
    margin-top: 10px;
    margin-right: 340px;
}

.linkedin-icon:hover {
    transition: all 0.6s;
    transform: scale(1.1);
    color: white;
}

.linkedin-icon:hover {
    transition: all 0.6s;
    transform: scale(1.1);
}

.message-box {
    line-height: 1.5; 
    padding-top: 7px; 
}

.send.thanks {
    background-color: #cef99c;
    color: #1a1a1a;
    font-weight: bold;
    font-size: 20px;
    font-family: "Trebuchet MS", "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", sans-serif;
}

.error {
    border: 2px solid rgb(249, 69, 69);
}

.mobile-break {
    display: none;
}

.desktop-break {
    display: inline;
}









  
  .login-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 40px;
    color: white;
    border-radius: 10px;
    margin-top: 20px;
  }
  
  .login-form h1 {
    margin: 0 0 24px 0;
}

  
  .form-input-material {
    position: relative;
    width: 100%;
    margin: 12px 0;
  }
  
  .form-input-material input,
  .form-input-material textarea {
    width: 100%;
    padding: 10px 0;
    background: transparent;
    border: none;
    border-bottom: 1px solid #8f8f8f;
    color: white;
    outline: none;
    resize: none;
  }

  .form-input-material input:hover, .form-input-material textarea:hover {
    width: 100%;
    padding: 10px 0;
    background: transparent;
    border: none;
    border-bottom: 1px solid #c3daff;
    color: white;
    outline: none;
    resize: none;
  }
  
  .form-input-material label {
    position: absolute;
    top: -20px;
    left: 0;
    color: white;
    pointer-events: none;
    transition: 0.2s ease all;
  }
  
  .form-input-material input:focus ~ label,
  .form-input-material input:not(:placeholder-shown) ~ label,
  .form-input-material textarea:focus ~ label,
  .form-input-material textarea:not(:placeholder-shown) ~ label {
    top: -30px;
    font-size: 14px;
    color: #56ff8e;;
  }
  
  .form-input-material input.error,
  .form-input-material textarea.error {
    border-bottom: 1px solid red;
  }
  
  .btn {
    position: relative;
    padding: 8px 20px;
    border-radius: 0;
    overflow: hidden;
    color: white;
    background-color: transparent;
    border: 1px solid white;
    cursor: pointer;
    transition: 0.3s;
    width: 100%;
    margin: 18px 0 9px 0;
    text-transform: uppercase;
  }
  
  .btn::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      120deg,
      transparent,
      #03a9f4,
      transparent
    );
    transform: translateX(-100%);
    transition: 0.6s;
  }

  .btn:hover::after {
    content: "→"; /* Pil-symbol */
    position: absolute;
    right: 340px; /* Juster afstanden fra højre kant */
    top: 50%;
    transform: translateY(-50%);
    font-size: 1.2em; /* Juster størrelsen på pilen */
    color: #ffffff; /* Juster farven på pilen */
    transition: opacity 0.3s;
    opacity: 1;
}

.btn::after {
    content: "→"; /* Pil-symbol */
    position: absolute;
    right: 30px; /* Juster afstanden fra højre kant */
    top: 50%;
    transform: translateY(-50%);
    font-size: 1.2em; /* Juster størrelsen på pilen */
    color: #ffffff; /* Juster farven på pilen */
    transition: opacity 0.3s;
    opacity: 0;
}

.btn:disabled {
    opacity: 0.6;
    cursor: not-allowed;
}
  
  .btn:hover {
    background: transparent;
    box-shadow: 0 0 20px 10px hsla(204, 70%, 53%, 0.5);
  }
  
  .btn:hover::before {
    transform: translateX(100%);
  }
  
  .btn:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }

  .success-text {
    color:#56ff8e;
  }
  


















@media (max-width: 768px) {
    .wrapper-contact {
        flex-direction: column;
        height: auto;
    }

    form {
        width: 350px; 
        margin-bottom: 10px;
        margin-left: -10px;
        margin-right: 30px;
    }

    .contact-header,
    .contact-subheader,
    .contact-text,
    .linkedin-icon {
        text-align: center;
        margin-right: 30px;
        margin-left: -100px;
    }

    .contact-header {
        font-size: 30px;
    }

    .contact-subheader {
        font-size: 18px;
    }

    .contact-text {
        font-size: 16px;
        text-align: center;
    }

    .linkedin-icon {
        margin: 10px 0;
        margin-right: 30px;
        margin-left: -100px;
    }

    .contact-content p {
        margin-right: auto;
    }

    .mobile-break {
        display: inline;
        margin-left: 30px;
    }

    .desktop-break {
        display: none;
    }

    .wrapper-contact {
        width: 100%;
        padding: 20px;
        border-bottom: 4px solid #1a1a1a;
        height: 930px;
        background-color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #fdfbc3; 
    }
}
