/* Standard styles for desktop */
.wrapper-about {
    width: 100%;
    border-bottom: 4px solid #1a1a1a;
    height: 930px;
    background-color: #000000;
    display: flex;
}

.column-left {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.column-right {
    flex: 1;
    background-color: #130f40;
    background-image: linear-gradient(315deg, #130f40 0%, #000000 74%);
    border-left: 4px solid #1a1a1a;
    display: flex;
    height: 930px;
}

.portrait-box {
    border: 4px solid #56ff8e;
    border-radius: 15px;
    width: 540px;
    height: 700px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 16px 0 8px 0 rgb(66, 251, 125);
}

.portrait {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: scale(0.7); /* Zoomer billedet lidt ud */
}

.portrait-mobile {
    display: none; /* Hidden by default */
}

.about-content {
    margin-top: 80px;
    margin-left: 120px;
    margin-right: 10%;
    text-align: left;
    font-size: 1.2rem; 
    margin-bottom: 20px;
    font-weight: inherit;
    color: #8f8f8f;
}

.about-content p {
    font-size: 1.2rem;
    margin-bottom: 20px;
    font-weight: inherit;
    color: #8f8f8f;
}

.about-content h1 {
    font-size: 3rem;
    color: #56ff8e;
    
}

strong {
    color:  #c3daff;
    font-size: 2.5rem;
}

/* Mobile */
@media (max-width: 768px) {
    .wrapper-about {
        flex-direction: column;
        height: auto;
    }

    .column-right, .column-left {
        flex: none;
        width: 100%;
        height: auto;
        border: none;
    }

    .column-right {
        background-color: rgb(149, 196, 253);
        border-top: 4px solid #1a1a1a;
        padding-bottom: 40px;
    }

    .column-left {
        padding-top: 0px;
    }

    .portrait-box {
        width: 70%;
        height: auto;
        margin: 20px auto;
        border: 4px solid #56ff8e;
        border-radius: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 16px 0 8px 0 rgb(66, 251, 125);
    }


    .portrait {
        width: 100%;
        height: auto;
        object-fit: cover;
        border-radius: 0;
    }

    .about-content {
        margin: 20px;
        text-align: left;
    }

    .label {
        font-size: 2rem; /* Font size for labels */
        font-weight: bold;
    }

    .value {
        font-size: 1rem; /* Font size for values */
        font-weight: normal;
    }

    .about-content .about-text {
        font-size: 1rem;
        margin-bottom: -10px;
        font-size: 1.2rem; 
        margin-bottom: 20px;
        font-weight: inherit;
        color: #8f8f8f;
    }

    .about-content h1 {
        font-size: 2rem;
    }
}

