.wrapper-intro {
    width: 100%;
    padding: 20px;
    border-bottom: 4px solid #1a1a1a;
    height: 1020px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #130f40;
    background-image: linear-gradient(315deg, #130f40 0%, #000000 74%);
}

.typewriter-title {
    display: flex;
    justify-content: flex-start; /* Align to start */
    align-items: center;
    margin-top: 50px;
    font-size: 5rem;
    font-weight: bold;
    color: #c3daff;
    font-family: monospace;
}

.intro-margin {
    margin-left: 20px;
}

@keyframes slideInFromLeft {
    0% {
        transform: translateX(-100%);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

.Mockup_Logo {
    width: 750px;
    height: 950px;
    margin-right: 100px;
    margin-left: -300px;
    margin-top: 60px;
    animation: slideInFromLeft 1s ease-in-out;
}

.right-column {
    display: flex;
    align-items: center;
}

.left-column {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: -100px;
    margin-left: 100px;
}

.intro-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
    text-align: left;
    max-width: 700px; 
    align-items: flex-start;
}

.intro-container h2 {
    font-size: 1.5rem; 
}

.intro-text {
    font-size: 1.2rem; 
    margin-bottom: 20px;
    font-weight: inherit;
    color: #505050;
}

h1 {
    color: #1a1a1a;
    margin-bottom: 10px;
    font-family: monospace;
    font-size: 3rem;
}

h2 {
    font-size: 25px;
    color: #6c87f5;
    font-weight: inherit;
    margin-top: 10px;
    font-family: monospace;
}

.intro-button-aboutMe,
.intro-button-projects,
.intro-button-contact {
    width: 500px;
    padding: 20px 115px;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    border-radius: 8px;
    border: 4px solid #1a1a1a;
    color: #505050;
    position: relative;
    margin-bottom: -10px;
    transition: all 0.3s ease;
}

.intro-button-aboutMe {
    background-color: transparent;
    /* border-radius: 2px solid #60ff85; */
    border-color: #56ff8e;
}

.intro-button-projects {
    background-color: transparent;
    border-color: #56ff8e;
}

.intro-button-contact {
    background-color: transparent;
    border-color: #56ff8e;
}

.intro-button-aboutMe:hover,
.intro-button-projects:hover,
.intro-button-contact:hover {
    transform: scale(1.1);
    color: #505050;
}

.intro-content, .intro-container {
    margin-left: 20px;
}

.intro-content h1 {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    font-size: 3rem;
    color: #000000;
    text-align: left; /* Align to start */
}

.intro-content h2 {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    text-align: left; /* Align to start */
}

.intro-content p {
    display: flex;
    flex-direction: column;
    margin-top: 5px;
    font-size: 1.2rem; 
    margin-bottom: 20px;
    font-weight: inherit;
    color: #8f8f8f;
    text-align: left; /* Align to start */
    margin-right: 30px;
}


.intro-button-aboutMe,
.intro-button-projects,
.intro-button-contact {
    width: 500px;
    padding: 20px 115px;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    border-radius: 8px;
    border: 4px solid #1a1a1a;
    color: #8f8f8f;
    position: relative;
    margin-bottom: -10px;
    background-color: transparent;
    border-color: #56ff8e;
    transition: all 0.3s ease;
}

.intro-button-aboutMe::after,
.intro-button-projects::after,
.intro-button-contact::after {
    content: '→';
    position: absolute;
    right: 30px;
    opacity: 0;
    transform: translateX(-10px);
    transition: opacity 0.3s ease, transform 0.3s ease;
    color: white;
}

.intro-button-aboutMe:hover::after,
.intro-button-projects:hover::after,
.intro-button-contact:hover::after {
    opacity: 1;
    transform: translateX(0);
}

.intro-button-aboutMe:hover,
.intro-button-projects:hover,
.intro-button-contact:hover {
    transform: scale(1.1);
    color: #8f8f8f;
}







/* Mobile */
@media (max-width: 768px) {
    .wrapper-intro {
        padding: 10px;
        height: auto;
    }

    .grid-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin-bottom: 50px;
    }

    .Mockup_Logo {
        width: 80%;
        height: auto;
        margin: 0;
        margin-top: 100px;
    }

    .right-column {
        width: 200%;
        margin-right: 20px;
    }

    .left-column {
        width: 100%;
        margin-left: -120px;
    }

    .intro-container {
        max-width: 100%;
        padding: 10px;
    }

    .intro-container h2 {
        font-size: 1.2rem;
    }

    .intro-container p {
        font-size: 1rem;
    }

    h1 {
        font-size: 2rem;
    }

    h2 {
        font-size: 1.5rem;
    }

    .intro-margin {
        margin-left: -45px;
    }

    .intro-button-aboutMe,
    .intro-button-projects,
    .intro-button-contact {
        margin-left: 90px;
        width: 60%;
        padding: 15px 0;
        font-size: 16px;
    }

    .typewriter-title {
        display: flex;
        justify-content: center; /* Center horizontally */
        align-items: center;
        font-size: 1.2rem;
        font-weight: bold;
        color: #ffffff;
        font-family: monospace;
        margin: 100px auto 0 -10px; /* Top, horizontal, bottom, left */
        max-width: 90%; /* Limit the width to 90% */
        word-wrap: break-word; /* Allow text to wrap */
    }

    .intro-content h2 {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
        text-align: left; /* Align to start */
    }

    .intro-content p {
        display: flex;
        flex-direction: column;
        margin-top: 5px;
        font-size: 1.2rem;
        margin-bottom: 20px;
        font-weight: inherit;
        color: #505050;
        text-align: left; /* Align to start */
        margin-right: 30px;
    }
}


