.footer-container {
    background-image: url('../Assets/Green.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 500px;
}

.footer-content {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    flex-wrap: wrap;
}

.footer-content .left,
.footer-content .center,
.footer-content .right {
    flex-direction: column;
    justify-content: center;
}

.footer-content .left {
    flex: 3; /* Den største kolonne */
    margin-left: 50px;
}

.left h1 {
    margin-left: 30px;
}

.left .copyright {
    color: #6e6e6e;
}

.left p {
    font-size: 1.2rem;
    margin-top: 190px;
    margin-left: -620px;
}

.footer-content .center,
.footer-content .right {
    flex: 1; /* Lige store kolonner for Code Community og Connect */
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Gør indholdet venstrejusteret */
    margin-top: 80px;
    color: #1a1a1a;
}

.center-content {
    display: flex;
    flex-direction: column;
    margin-top: -200px;
}

.right-content {
    display: flex;
    flex-direction: column;
    margin-top: -132px;
}

.footer-content .center h2,
.footer-content .right h2 {
    font-size: 2rem;
    color: #1a1a1a;
    margin-bottom: 20px;
}

.center a,
.right a {
    color: #1a1a1a; /* Link farve */
    text-decoration: none; /* Fjern understregning */
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 10px; /* Giver plads mellem links */
    text-align: left;
}

.center a:hover,
.right a:hover {
    color: #bbbbbb;
    transition: all 0.3s ease;
}

.footer-content .right {
    text-align: left; /* Venstrejustering af indhold */
    margin-right: 20px;
}

.footer-content h1 {
    font-size: 7rem; /* Mindre font-size for at passe til layoutet */
    text-align: left;
}

.footer-content p {
    font-size: 1.2rem;
    color: #1a1a1a;
}

.center-logo {
    max-height: 30px;
    max-width: 35px;
    margin-bottom: 10px;
    margin-top: 10px;
    transform: rotate(-20deg);
}

/* Mobile */
@media (max-width: 768px) {
    .footer-content {
        flex-direction: column;
        align-items: center;
    }

    .footer-content .left,
    .footer-content .right {
        text-align: center;
        margin: 10px 0;
    }

    .center-logo {
        margin: 10px 0;
    }
}
