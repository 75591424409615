.wrapper-skills {
    width: 100%;
    border-bottom: 4px solid #1a1a1a;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 240px 0;
}

/* Layout container */
.skills-layout {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 40px;
}

/* Left section card */
.skill-section-card {
    background-image: linear-gradient(315deg, #100A3D 0%, #000000 74%);
    border: 2px solid #5A1FD0;
    padding: 20px;
    border-radius: 15px;
    width: 300px;
    color: white;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 50px;
}

.heading {
    color: #56ff8e;
}

.skills-info {
    margin-top: 20px;
    font-size: 1.1rem;
    line-height: 1.5;
    color: #8f8f8f;
}

/* Right section cards */
.skills-container {
    display: flex;
    flex-direction: row;
    gap: 40px;
    margin-right: 59px;
}

.skill-card {
    background-image: linear-gradient(315deg, #100A3D 0%, #000000 74%);
    border: 2px solid #5A1FD0;
    padding: 20px;
    border-radius: 15px;
    width: 250px;
    min-height: 350px; /* Sørg for, at alle kort har samme minimumshøjde */
    text-align: center;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: flex-start; /* Juster indhold til toppen */
    align-items: flex-start;
    padding-bottom: 42px;
}

/* Ensartet højde og placering af overskriften */
.skill-card h2 {
    font-size: 1.5rem;
    margin-bottom: 15px;
    color: white;
    text-align: start;
    margin-left: 20px;
    height: 60px; /* Ensartet højde for overskrifter */
    display: flex;
    align-items: center; /* Vertikal centrering inden for højde */
    margin-top: 30px;
    padding-top: 0;
}

/* Sikrer, at alle skill-cards har samme padding og størrelse */
.skill-list {
    list-style: none;
    padding-left: 20px; /* Add space for better left alignment */
    font-size: 1rem;
    color: #8f8f8f;
}

.skill-list li {
    display: flex;
    align-items: center;
    margin: 10px 0;
    font-weight: 500;
}

.skill-list li .circle {
    margin-right: 10px;
}

/* Skill level indicator styling */
.skill-level-indicators {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
}

.skill-level-indicator {
    display: flex;
    align-items: center;
    font-size: 1rem;
    color: #8f8f8f;
}

.circle {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 10px;
}

.beginner {
    background: #ff4f4f28;
    border-color: #ff4f4f;
    border: 2px solid #ff4f4f;
}

.advanced {
    background: #4fa0ff28;
    border-color: #4fa0ff;
    border: 2px solid #4fa0ff;
}

.expert {
    background: #52ff4f28;
    border-color: #52ff4f;
    border: 2px solid #52ff4f;
}

.master {
    background: #674fff28;
    border-color: #bb96ff;
    border: 2px solid #bb96ff;
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
    .skills-layout {
        flex-direction: column;
        align-items: center;
    }

    .skill-section-card {
        margin-bottom: 20px;
    }

    .skills-container {
        flex-direction: column;
    }

    .skill-card {
        width: 100%;
        margin-bottom: 20px;
    }
}