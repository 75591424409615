/* Desktop */
@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:ital,wght@0,100..800;1,100..800&family=Poppins:wght@100;400&display=swap');

.poppins-thin {
  font-family: "Poppins", system-ui;
  font-weight: 400;
  font-style: normal;
}

.hover-underline-animation {
  display: inline-block;
  position: relative;
  color: #56ff8e;
}

.hover-underline-animation:after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #56ff8e;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.hover-underline-animation:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}


/* Desktop */
.wrapper-navbar {
display: flex;
align-items: center;
width: 100%;
padding: 20px;
border-bottom: 4px solid #1a1a1a;
height: 80px;
box-sizing: border-box;
position: fixed; 
top: 0; 
left: 0;
background-color: #1a1a1a;
z-index: 1000;
}

.logo-container {
display: flex;
align-items: center;
margin-right: auto;
margin-left: 20px;
width: 500px;
}

.logo-container a {
text-decoration: none;
}

.navbar-logo {
width: 50px;
height: auto;
transform: rotate(-20deg);
color:#ffffff;
font-size: 28px;
}

.jetbrains-mono-code-header {
  font-family: "JetBrains Mono", monospace;
  font-optical-sizing: auto;
  font-weight: 800;
  font-style: normal;
}

.bracket {
  font-size: 1.7em;
  vertical-align: middle;
  color: #6c87f5;
}

.navbar-logo:hover {
cursor: pointer;
transition: all 0.6s ease;
border-radius: 8px;
transform: scale(1.2);
}

.menu-icon {
display: none;
cursor: pointer;
font-size: 24px;
margin-right: 20px;
}

.buttons-container {
display: flex;
gap: 10px;
margin-top: -10px;
margin-bottom: 10px;
justify-content: flex-end;
width: 100%;
}

.styled-button {
padding: 10px 10px;
margin-right: 20px;
font-size: 18px;
cursor: pointer;
background-color: transparent;
color: #8f8f8f;
border: none;
}

#aboutMe:hover,
#projects:hover,
#contact:hover,
#skills:hover {
transition: all 0.6s ease;
border-radius: 8px;
color: #505050;
padding: 10px 10px;
}

#aboutMe:hover {
color: white;
}

#projects:hover {
color: white;
}

#contact:hover {
color: white;
}

#skills:hover {
color: white;
}








/* Mobile */
@media (max-width: 768px) {
  .menu-icon {
    display: block;
    color: #ffffff;
    cursor: pointer;
  }

  .buttons-container {
    position: fixed;
    top: 80px;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #464646;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transform: translateX(-100%); /* Start hidden */
    transition: transform 0.3s ease-in-out;
  }

  .buttons-container.open {
    transform: translateX(0); /* Show the menu when open */
  }

  /* Initial state for buttons - start lower on the screen */
  .styled-button {
    margin-right: 0;
    font-size: 20px;
    padding: 20px 30px;
    width: 100%;
    text-align: center;
    color: #95b4ff;
    opacity: 0;
    transform: translateY(300px); /* Start much lower on the screen */
    transition: opacity 0.5s ease, transform 0.5s ease;
  }

  /* Slide-up effect with individual delay */
  .buttons-container.open .styled-button:nth-child(1) {
    opacity: 1;
    transform: translateY(0);
    transition-delay: 0.1s;
  }
  .buttons-container.open .styled-button:nth-child(2) {
    opacity: 1;
    transform: translateY(0);
    transition-delay: 0.3s;
  }
  .buttons-container.open .styled-button:nth-child(3) {
    opacity: 1;
    transform: translateY(0);
    transition-delay: 0.5s;
  }
  .buttons-container.open .styled-button:nth-child(4) {
    opacity: 1;
    transform: translateY(0);
    transition-delay: 0.7s;
  }

  .navbar-logo {
    width: 40px;
    height: auto;
    transform: rotate(-20deg);
  }

  .navbar-logo:hover {
    cursor: pointer;
    transition: all 0.6s ease;
    border-radius: 8px;
    transform: scale(1.2);
  }

  #aboutMe:hover,
  #projects:hover,
  #contact:hover,
  #skills:hover {
    transform: none;
  }
}


