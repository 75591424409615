/* Generel styling */
.wrapper-projects {
    width: 100%;
    padding: 20px;
    border-bottom: 4px solid #1a1a1a;
    height: auto;
    /* Ændret baggrund til en større gradient */
    background-image: linear-gradient(to bottom, #000000 50%, #4D00CC 100%);
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Tilføjelse af top-left tekst i cards-container */
.top-left-text {
    position: absolute; /* Absolut positionering i forhold til den nærmeste relative parent */
    top: 0px; /* Juster denne værdi for at placere teksten højere */
    left: 0;
    /* background-color: rgba(0, 0, 0, 0.7); Gennemsigtig baggrund */
    color: white;
    border-radius: 5px;
    font-size: 3.2rem;
    z-index: 10; /* Sørg for at teksten vises over andre elementer */
    text-align: start;
    padding: 20px;
    margin-left: 40px;

}

.right-side {
    position: relative; /* Parent container skal have relative positionering */
}

.left-side {
    text-align: center;
    margin-bottom: 20px;
    color: #8f8f8f;
    font-size: 1.2rem;
}

.left-side h1 {
    color: #56ff8e;
}

.left-side h2 {
    color: #c3daff;
}

.left-side p {
    margin-top: -15px;
}

.right-side {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cards-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 kolonner */
    gap: 30px;
    padding: 60px;
    padding-top: 120px; /* Tilføj ekstra padding for at skubbe kortene længere ned */
    width: 90%;
    background-color: #001E22;
    border-radius: 30px;
    margin-top: 40px; /* Alternativt kan du bruge margin-top til at skubbe containeren ned */
}

.card {
    background: linear-gradient(135deg, #0B5B44, #022727);
    border-radius: 15px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    height: 200px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    border: 1px solid #0AB49D;
    margin-top: 20px;
    text-align: start;
}

.card-button {
    text-decoration: none; /* Fjern den blå streg */
    color: inherit;
}

.card:hover {
    transform: translateY(-10px);
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.3);
    background: linear-gradient(135deg, #10664e, #034e4e); /* Lysere gradient ved hover */
}

.card-content {
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.card h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
}

.card p {
    font-size: 1rem;
    flex-grow: 1;
}

.card-button {
    align-self: flex-end;
    background: none;
    border: none;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
    padding: 0;
    display: flex;
    align-items: center;
}

.card-button span {
    margin-right: 5px; /* Adjust spacing between text and the arrow */
    font-size: 1rem; /* Adjust the text size */
    color: white;
}

.thin-arrow {
    font-size: 1.2rem; /* Adjust the size of the arrow */
    color: white;
    transition: transform 0.3s ease-in-out;
    text-decoration: none; /* Ensure the arrow doesn't get underlined */
}

/* Only underline the "Read More" text, not the arrow */
.card-button:hover span {
    text-decoration: underline;
    text-decoration-color: white;
    text-underline-offset: 4px;
}

/* Arrow movement on hover, no underline */
.card-button:hover .thin-arrow {
    transform: translateX(5px); /* Move arrow slightly on hover */
    text-decoration: none; /* Ensure no underline is applied */
}

/* Optional: Slight translation for the whole button without affecting underline */
.card-button:hover {
    transform: translateX(2px);
}






.bottom-boxes-container {
    display: flex;
    justify-content: space-between; /* Fordel boksene med plads imellem */
    gap: 30px; /* Mellemrum mellem boksene */
    padding: 60px;
    width: 152%; /* Samme bredde som cards-container */
    margin-top: 40px;
    margin-left: -35%; /* Juster denne værdi for at centrere boksene */
}

.box {
    background-color: transparent;
    border-radius: 15px;
    border: 2px solid #404040;
    padding: 20px;
    width: 48%; /* Hver boks fylder næsten halvdelen af containeren */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    text-align: start;
}


.box:hover {
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.3);
    border: 2px solid #6e6e6e;
    background-color: #1A1A1A;
}

.box h3 {
    color: white;
    font-size: 1.5rem;
}

.box p {
    color: #8f8f8f;
    font-size: 1rem;
}



/* Mobile responsivitet */
@media (max-width: 768px) {
    .cards-container {
        grid-template-columns: 1fr; /* 1 kolonne */
        gap: 10px;
    }

    .card {
        height: 250px;
    }

    .card-button {
        padding: 8px 16px;
    }
}

/* Tablet responsivitet */
@media (min-width: 769px) and (max-width: 1024px) {
    .cards-container {
        grid-template-columns: repeat(2, 1fr); /* 2 kolonner */
    }
}
